module.exports = {
	"email": "E-Mail",
	"phone": "Telefon",
	"name": "Name",
	"avatar": "Profilbild",
	"save": "Speichern",
	"saved": "Speichern",
	"saving": "Wird gespeichert",
	"edit": "Bearbeiten",
	"create": "Anlegen",
	"remove": "Löschen",
	"reinvite": "Erneut einladen",
	"createUser": "Benutzer anlegen",
	"loading": "Lade {resource}",
	"dashboard": "Dashboard",
	"project": "Projekt | Projekte",
	"theProject": "Das Projekt",
	"map": "Karte",
	"yourProject": "Ihr Projekt | Ihre Projekte",
	"allProjects": "Alle Projekte",
	"noProjects": "Keine Projekte",
	"residential": "Wohnhaus | Wohnhäuser",
	"noResidentials": "Keine Wohnhäuser",
	"residentialTemplate": "Wohnhaustyp | Wohnhaustypen",
	"createResidentialTemplate": "Wohnhaustyp anlegen",
	"createUnitTemplate": "Einheitentyp anlegen",
	"user": "Benutzer | Benutzer",
	"settings": "Einstellungen",
	"login": "Anmelden",
	"logout": "Abmelden",
	"termsCondition": "Vertragsbedingungen",
	"privacyPolicy": "Datenschutz",
	"cookiePolicy": "Cookie-Richtlinie",
	"menu": "Menü",
	"followUs": "Folgen Sie uns",
	"getStarted": "Loslegen",
	"noAccess": "Kein Zugriff",
	"projectName": "Projektname",
	"publish": "Veröffentlichen",
	"publishing": "Veröffentlichung",
	"published": "Veröffentlicht",
	"unpublished": "Nicht veröffentlicht",
	"domain": "Domäne",
	"retry": "Erneut versuchen",
	"close": "Schließen",
	"showDetails": "Details anzeigen",
	"hideDetails": "Details ausblenden",
	"createLead": "Neuer Interessent",
	"newLead": "Neuer Interessent",
	"lead": "Interessent | Interessenten",
	"leadMessage": "Bescheid",
	"message": "Nachricht | Nachrichten",
	"newMessage": "Neue Nachricht",
	"comment": "@:message",
	"noMessages": "Keine Nachrichten",
	"description": "Beschreibung",
	"showMore": "Mehrere anzeigen",
	"noLeads": "Keine Interessenten",
	"openhouse": "Besichtigung | Besichtigungen",
	"openhouseOnRequest": "Besichtigung nach Absprache",
	"noOpenhouse": "Keine Besichtigungen",
	"createOpenhouse": "Neue Besichtigung",
	"from": "Von",
	"to": "Bis",
	"date": "Datum",
	"role": "Rolle | Rollen",
	"flatfinder": "Wohnhausauswahl | Wohnhausauswahlen",
	"unitSelector": "Einheitenauswahl | Einheitenauswahlen",
	"flatfinderTypeFlat": "Wohnungsauswahl | Wohnungsauswahlen",
	"flatfinderTypePlot": "Grundstücksauswahl | Grundstücksauswahlen",
	"flatfinderTypeOffice": "Büro | Büros",
	"createFlatfinder": "Neue Wohnhausauswahl",
	"createUnitSelector": "Neue Einheitenauswahl",
	"home": "Startseite",
	"statistics": "Statistik",
	"notset": "Nicht gesetzt",
	"projectmanager": "Projektmitarbeiter | Projektmitarbeiter",
	"customermanager": "Kundenbearbeiter | Kundenbearbeiter",
	"complaintmanager": "Reklamationsverantwortlicher | Reklamationsverantwortliche",
	"search": "Suchen",
	"searchFor": "Suchen nach",
	"cancel": "Abbrechen",
	"confirm": "Bestätigen",
	"couldnotfindonquery": "Kein Treffer für \"{query}\"",
	"selectx": "{x} auswählen",
	"brochure": "Prospekt",
	"downloadBrochure": "Prospekt herunterladen",
	"logo": "Logo",
	"logoInverted": "Logo (helle Variante)",
	"favicon": "Favicon (Browser-Icon)",
	"gallery": "Galerie",
	"internalId": "Interne ID",
	"createProject": "Neues Projekt",
	"address": "Adresse",
	"postcode": "Postleitzahl",
	"city": "Ort",
	"county": "Regierungsbezirk",
	"latitude": "Breitengrad",
	"longitude": "Längengrad",
	"position": "Position",
	"createResidential": "Neues Wohnhaus",
	"cadastre": "Liegenschaftskataster",
	"residentialnumber": "Wohnungsnummer",
	"number": "Nummer",
	"street": "Straßenadresse",
	"floorplan": "Grundriss | Grundrisse",
	"dragImageOrDrop": "Das Bild hierher ziehen oder zum Hochladen <b>drücken</b> | Bilder hierher ziehen oder zum Hochladen <b>drücken</b>",
	"dragFilesOrDrop": "Dateien {extension} hierher ziehen oder zum Hochladen <b>drücken</b>",
	"dragFileOrDrop": "Datei {extension} hierher ziehen oder zum Hochladen <b>drücken</b>",
	"price": "Preis",
	"residentialProperties": "Hauptdaten",
	"propertyType": "Einheitentyp",
	"propertyTypeWarning": "<b>Achtung!</b> Sie haben den Wohnhaustyp \"{value}\" ausgewählt und müssen deshalb Werte pro Einheit eingeben.",
	"numberOfBedrooms": "Schlafzimmer",
	"numberOfRooms": "Zimmer",
	"numberOfBathrooms": "Bad/WC",
	"primaryRoomArea": "Primäres Zimmer",
	"grossArea": "Bruttofläche",
	"useableArea": "Nutzfläche",
	"floor": "Etage",
	"numberOfFloors": "Anzahl Etagen",
	"plotArea": "Grundstücksfläche",
	"ownershipType": "Eigentumsform",
	"numberOfParkingSpots": "Anzahl Stellplätze",
	"energyLabel": "Energiekennzeichnung",
	"energyLabelColor": "",
	"plotOwned": "Grundstück in Besitz",
	"plotGroundTax": "Grundsteuer",
	"plotOwnershipType": "Eigentumsverhältnis (Grundstück)",
	"communityTax": "Kommunalabgaben",
	"taxValue": "Vermögenswert",
	"constructionYear": "Baujahr",
	"renovatedYear": "Renoviert Jahr",
	"value": "Wert",
	"add": "Hinzufügen",
	"custom": "Benutzerdefiniert",
	"yes": "Ja",
	"no": "Nein",
	"quantity": "Anzahl",
	"none": "Keine",
	"bid": "Kaufen",
	"addBid": "Neues Gebot",
	"bidUrl": "Kauflink",
	"purchaseUrl": "Link zur Unterzeichnung des Kaufvertrags",
	"size": "Größe",
	"sale": "Verkauf",
	"basis": "Grundlage",
	"presentation": "Präsentation",
	"integration": "Integration | Integrationen",
	"next": "Nächste",
	"prev": "Vorherige",
	"cover": "Cover",
	"propertyType:detached": "Einfamilienhaus | Einfamilienhäuser",
	"propertyType:flat": "Wohnung | Wohnungen",
	"propertyType:terraced": "Reihenhaus",
	"propertyType:semidetached": "Zweifamilienhaus",
	"propertyType:plot": "Hausgrundstück | Hausgrundstücke",
	"propertyType:leisure": "Hütte | Hütten",
	"propertyType:leisurePlot": "Freizeitgrundstück | Freizeitgrundstücke",
	"propertyType:office": "Büro | Büros",
	"openhouseReferences": "Wo sind Besichtigungen",
	"isInterestedIn": "Bin interessiert an",
	"leadReferences": "@:isInterestedIn",
	"theWholeProject": "Gesamtes Projekt",
	"getInTouch": "Kontaktieren Sie uns",
	"leadFormTitle": "Interesse melden",
	"leadConfirm": "Danke für Ihr Interesse",
	"residentialStatus": {
		"draft": "@:unpublished",
		"upcoming": "Kommt zum Verkauf",
		"sale": "Zu verkaufen",
		"sold": "Verkauft",
		"reserved": "Reserviert"
	},
	"residentialStatus:sale": {
		"draft": "@:unpublished",
		"upcoming": "Kommt zum Verkauf",
		"sale": "Zu verkaufen",
		"sold": "Verkauft",
		"reserved": "Reserviert",
		"development": "In Entwicklung"
	},
	"residentialStatus:rent": {
		"draft": "@:unpublished",
		"upcoming": "Kommt zur Vermietung",
		"reserved": "Reserviert",
		"rent": "Zu vermieten",
		"rented": "Vermietet",
		"development": "In Entwicklung"
	},
	"viewResidentials": "Wohnhäuser ansehen",
	"status": "Status",
	"bidAction": "Kaufen",
	"residentialSaleFee": "Kosten",
	"residentialSaleFixed": "Festpreis",
	"residentialSaleFeeIncluded": "Angegebener Preis, inkl. Kosten",
	"contact": "Kontakt | Kontakte",
	"editContact": "Kontakt ändern",
	"createContact": "Kontakt anlegen",
	"contactUpsell": "Ich wünsche Informationen zu ähnlichen Projekten",
	"leadPrivacy": "Ich möchte kontaktiert werden nach Einwilligung von {0}",
	"contractPrivacy": "Ich bin einverstanden mit {0}",
	"salesmanVisibility": "Kontaktinformationen der Kundenbearbeiter von diesem Projekt werden auf der Projekt-Website angezeigt",
	"areYouSure": "Sind Sie sicher?",
	"somethingWentWrong": "Etwas ist schiefgelaufen",
	"completed": "Abgeschlossen",
	"complete": "Abschließen",
	"completing": "Wird abgeschlossen",
	"youAreAboutToRemove": "Sie löschen gerade",
	"removeRelationsTitle": "Sie löschen auch zugehörige:",
	"showAll": "Alle anzeigen",
	"attachment": "Anlage | Anlagen",
	"preview": "Vorschau anzeigen",
	"transferToUser": "Verschiebe verbundene Daten nach:",
	"upcomingDeadlines": "Anstehende Fristen",
	"noDeadlines": "Keine anstehenden Fristen",
	"recentActivity": "Letzte Ereignisse",
	"noActivity": "Keine Ereignisse",
	"task": "Aufgabe | Aufgaben",
	"noTasks": "Keine Aufgaben",
	"new": "Neu",
	"showCoordinates": "Koordinaten anzeigen",
	"readOurTerms": "Vertragsbedingungen durchlesen",
	"iAcceptTerms": "Ich habe die Vertragsbedingungen gelesen und stimme ihnen zu",
	"sendAndAccept": "Weiter",
	"privacy": "Datenschutzerklärung",
	"notfoundTitle": "Die Seite existiert nicht",
	"projectWillPublish": "Wird bald veröffentlicht",
	"projectWillPublishAt": "Wird veröffentlicht am {date}",
	"projectUnpublished": "Das Projekt ist abgeschlossen",
	"pageMadeBy": "Die Seite wurde erstellt in",
	"processedBy": "Bearbeitet von",
	"markAsProcessed": "Als bearbeitet markieren",
	"openhouseAtValue": "Besichtigung über {value}",
	"noMoreResidentials": "Keine weiteren Wohnhäuser",
	"welcomeName": "Willkommen, {name}",
	"youHaveBeenAddedToCompany": "Sie wurden als Benutzer bei {company} hinzugefügt.",
	"activateAccount": "Konto aktivieren",
	"pressButtonToActivateAccount": "Drücken Sie zur Auswahl Ihres Passwortes und zum Aktivieren Ihres Kontos auf die Drucktaste.",
	"orgNo": "Org. Nr.",
	"howToGetLatLng": "Um das Projekt auf der Karte zu platzieren, empfehlen wir Ihnen, {0} zu verwenden, um die richtigen Koordinaten zu entnehmen. Die Adressenabfrage enthält oft keine neuen Adressen, und Sie müssen diese deshalb manuell entnehmen. Außerdem wird die Position genauer.",
	"image": "Bild | Bilder",
	"flatfinderBuilder": "Layout",
	"leaflet-v1": {
		"drawToolbar": {
			"actions": {
				"title": "Zeichnung abbrechen",
				"text": "Abbrechen"
			},
			"finish": {
				"title": "Zeichnung abschließen",
				"text": "Abschließen"
			},
			"undo": {
				"title": "Den zuletzt gezeichneten Punkt löschen",
				"text": "Den letzten Punkt löschen"
			},
			"buttons": {
				"polygon": "Eine Markierung zeichnen"
			}
		},
		"drawHandlers": {
			"polygon": {
				"error": "Falsch!",
				"tooltip": {
					"start": "Zum Zeichnen einer Markierung drücken.",
					"cont": "Zum Fortsetzen mit dem Zeichnen einer Markierung drücken.",
					"end": "Drücken Sie auf den ersten Punkt, um diese Markierung zu schließen."
				}
			}
		},
		"editToolbar": {
			"actions": {
				"save": {
					"title": "Änderungen speichern.",
					"text": "Speichern"
				},
				"cancel": {
					"title": "Bearbeitung abbrechen, alle Änderungen verwerfen.",
					"text": "Abbrechen"
				},
				"clearAll": {
					"title": "Alle Markierungen löschen.",
					"text": "Alle löschen"
				}
			},
			"buttons": {
				"edit": "Markierungen bearbeiten.",
				"editDisabled": "Keine Markierungen zu bearbeiten.",
				"remove": "Markierungen löschen.",
				"removeDisabled": "Keine Markierungen zu löschen."
			}
		},
		"editHandlers": {
			"edit": {
				"tooltip": {
					"text": "Punkte ziehen oder zum Bearbeiten markieren.",
					"subtext": "Auf Abbrechen drücken, um Änderungen zu verwerfen."
				}
			},
			"remove": {
				"tooltip": {
					"text": "Auf eine Markierung drücken, um zu löschen."
				}
			}
		}
	},
	"theme": "Thema",
	"selectTheme": "Thema auswählen",
	"show": "Anzeigen",
	"activateMarketingButton": "Zusätzliche Sichtbarkeit kaufen",
	"couldNotLoadContent": "Konnte den Inhalt nicht laden",
	"couldNotLoadContentMessage": "Versuchen Sie, die Seite erneut zu laden. Wenn das Problem andauert, wenden Sie sich bitte an {0}.",
	"personalInformation": "Persönliche Angaben",
	"bidInformation": "Kaufinformationen",
	"bidAuthentication": "Legitimation",
	"bidSigning": "Unterzeichnung",
	"bidConfirmation": "Gebot unterzeichnet",
	"purchaseSigning": "Unterzeichnung",
	"purchaseConfirmation": "Kaufvertrag unterzeichnet",
	"socialSecurityNumber": "Personenkennzeichen",
	"bidApplicant": "Bieter",
	"bidHasCoApplicant": "Mitkäufer hinzufügen",
	"purchaseHasCoApplicant": "@:bidHasCoApplicant",
	"bidCoApplicant": "Mitkäufer",
	"amount": "Betrag",
	"bidExpiresAt": "Bindefrist",
	"bidTakeoverAt": "Gewünschte Übernahme",
	"bidCondition": "Eventuelle Vorbehalte",
	"passwordEnforcementMessage": "Das Passwort muss aus mindestens 8 Zeichen, Groß-/Kleinbuchstaben und mindestens 1 Zahl bestehen",
	"bank": "Bank",
	"financeContact": "Ansprechpartner",
	"financingPlan": "Finanzierungsplan",
	"signingAuthority": "Unterzeichnungsbevollmächtigter",
	"bidContract": "Kaufbestätigung",
	"purchaseContract": "Kaufvertrag",
	"shopContract": "Vertrag über optionale Ausstattung",
	"shop-partialContract": "Vertrag über optionale Ausstattung - Teilunterzeichnung",
	"bidSignedMessage": "Ihr Gebot wurde unterzeichnet und gesendet. Sie werden in Kürze eine E-Mail mit der Bestätigung erhalten. Sobald Ihr Gebot bearbeitet wurde, erhalten Sie per E-Mail eine Antwort.",
	"purchaseSignedMessage": "Ihr Kaufvertrag wurde unterzeichnet und gesendet. Sie werden in Kürze eine E-Mail mit der Bestätigung erhalten. Sobald Ihr Kaufvertrag bearbeitet wurde, erhalten Sie per E-Mail eine Antwort.",
	"gotoProject": "Zum Projekt gehen",
	"parent": "Elternteil",
	"goBack": "Zurückgehen",
	"removeDemoContent": "Demo-Inhalt löschen",
	"unsavedChangesDialog": "Wenn Sie fortsetzen, gehen ungesicherte Änderungen verloren. Wollen Sie wirklich fortfahren?",
	"confirmation": "Bestätigung",
	"yourDocumentsAreAttached": "Im Anhang finden Sie alle Dokumente",
	"accept": "Genehmigen",
	"decline": "Ablehnen",
	"document": "Dokument | Dokumente",
	"noDocuments": "Keine Dokumente",
	"projectsite": "Projekt-Website",
	"signer": "Unterzeichnender | Unterzeichnende",
	"youDoNotHaveSignAuthority": "Nur Unterzeichnungsbevollmächtigte können dieses Dokument genehmigen/ablehnen",
	"update": "Aktualisieren",
	"fieldsToSync": "Daten, die überschrieben werden sollen",
	"selectAll": "Alle auswählen",
	"residentialTemplateSyncReferences": "Alle verknüpften Wohnhäuser aktualisieren",
	"askResidentialTemplateSyncReferences": "Möchten Sie die verknüpften Wohnhäuser aktualisieren?",
	"projectDomainSetup": "Damit die Änderungen im Netz sichtbar werden, müssen Sie einen DNS-Zeiger für Ihre Domäne anlegen (siehe unten). Bitte beachten Sie, dass es eine Weile dauern kann, bis die Änderung im Netz sichtbar wird. Chatten Sie mit uns, wenn Sie Fragen haben.",
	"lookingForAUserAdd": "Finden Sie nicht den Benutzer, nach dem Sie suchen?",
	"select": "Auswählen",
	"syncReferencesWarning": "Seien Sie bei der Auswahl der zu überschreibenden Daten vorsichtig. Alle ausgewählten Felder werden mit dem neuen Inhalt überschrieben.",
	"themeDoesNotSupportColor": "Das ausgewählte Thema unterstützt nicht die Möglichkeit für das Thema Farbe.",
	"themeColor": "Thema Farbe",
	"youAreHere": "Sie sind hier",
	"resetSelection": "Auswahl zurücksetzen",
	"selection": "Auswahl",
	"thisIsADemo": "Dies ist eine Demo",
	"userIsGuest": "Gast / Hat keinen Zugriff auf das System",
	"guest": "Gast",
	"inviteSent": "Einladung gesendet",
	"demo": "Demo",
	"thisMessageHasBeenSentTo": "Dieser Bescheid wurde gesendet an",
	"tourSeoAnalyticsTagManager": "SEO / Analytics / Tag Manager",
	"tourDeveloperTools": "Verwendung von Entwicklerwerkzeug",
	"tourBusinessSite": "So ändern Sie die Betriebsseite ",
	"tourRecommendedImageFormat": "Empfohlene Bildformate",
	"tourHowToAddUser": "So fügen Sie einen Benutzer hinzu",
	"tourHowToCreateProjects": "So legen Sie Projekte an",
	"tourSingleResidentialSale": "Reihenhausverkauf ",
	"tourHowToAddLeads": "So fügen Sie Interessenten hinzu",
	"tourHowToCreateResidentials": "So legen Sie Wohnhäuser an",
	"addonPresentation": "Präsentation",
	"addonBid": "Bieten",
	"addonPurchase": "Kaufvertrag",
	"addonShop": "@:shop",
	"addonShopPurchase": "Shop für optionale Ausstattung - Unterzeichnung",
	"tourAboutProjectDashboard": "Über das Projekt-Dashboard",
	"tourHowToEditResidentials": "So bearbeiten Sie Wohnhäuser",
	"tourHowToDeleteResidentials": "So löschen Sie Wohnhäuser",
	"tourTitleOnScreen": "Hilfsguides (Interaktiv)",
	"tourTitleCourseVideos": "Schulungsvideos ",
	"tourTitleManuals": "Hilfsguides (Handbuch)",
	"flatfinderIsEntry": "Haupt-Einheitenauswahl (diesen auf der Projektseite zuerst anzeigen)",
	"flatfinderCreateResidentialWarning": "Wenn Sie eine Wohnhausauswahl erstellen, müssen Sie diese mit Wohnhäusern verbinden. Deshalb ist es wichtig, dass Sie die Wohnhäuser zuerst anlegen, bevor Sie die Wohnhausauswahl erstellen.",
	"flatfinderCreateResidentialWarningTitle": "Wie wir sehen, haben Sie keine Wohnhäuser angelegt",
	"flatfinderCreateResidentialWarningException": "Wenn Sie jetzt keine Wohnhäuser erstellen wollen, können Sie von diesem Bescheid absehen und fortsetzen.",
	"continue": "Fortsetzen",
	"standard": "Standard",
	"richTextUpdateLink": "Link aktualisieren",
	"richTextAddLink": "Link hinzufügen",
	"multiplicatorMonthly": "Monat",
	"multiplicatorProject": "Projekt",
	"contractStatusAccepted": "Genehmigt",
	"contractStatusDeclined": "Abgelehnt",
	"contractStatusDraft": "Entwurf",
	"contractStatusSigned": "Ein Dokument wartet auf Genehmigung",
	"contractStatusUnsigned": "Sie haben ein Dokument zum Unterzeichnen",
	"bidErrorStatus": "Das Wohnhaus steht nicht zum Verkauf",
	"bidErrorAddon": "Das Modul zum Bieten ist für dieses Projekt nicht aktiviert",
	"purchaseErrorStatus": "Das Wohnhaus ist nicht verkauft",
	"purchaseErrorAddon": "Das Modul für den Kaufvertrag ist für dieses Projekt nicht aktiviert",
	"signed": "Unterzeichnet",
	"gotoSigning": "Zur Unterzeichnung gehen",
	"sign": "Unterzeichnen",
	"username": "Benutzername",
	"accountUrl": "Link zum Konto",
	"password": "Passwort",
	"otherBidAcceptedMessage": "Ein anderes Gebot wurde angenommen",
	"bidConfirmationMessage": "Wir haben Ihr Gebot erhalten und werden Ihnen so schnell wie möglich antworten",
	"bidAcceptMessage": "Ihr Gebot wurde angenommen",
	"bidDeclinedMessage": "Ihr Gebot wurde abgelehnt",
	"purchaseConfirmationMessage": "Wir haben Ihren Kaufvertrag erhalten und werden Ihnen so schnell wie möglich antworten",
	"purchaseAcceptMessage": "Ihr Kaufvertrag wurde angenommen",
	"purchaseDeclinedMessage": "Ihr Kaufvertrag wurde abgelehnt",
	"shopConfirmationMessage": "Wir haben Ihre unterzeichnete Bestellung der optionalen Ausstattung erhalten. Wenn die Gegenpartei diese unterzeichnet hat, erhalten Sie eine Bestätigung. Im Anhang finden Sie eine Übersicht über Ihre Bestellung.",
	"shopAcceptMessage": "Ihr Vertrag über optionale Ausstattung wurde angenommen",
	"shopDeclinedMessage": "Ihr Vertrag über optionale Ausstattung wurde abgelehnt",
	"contractExpiredMessage": "Die Frist ist abgelaufen",
	"reason": "Begründung",
	"pressHereToCancelBid": "Wollen Sie den Kauf stornieren?",
	"youAreUsingBidAddon": "Sie verwenden Kvass - Bieten",
	"pressHere": "Hier drücken",
	"void": "Stornieren",
	"reasonWillBeSentToApplicants": "Dieser Bescheid wird an den Kunden weitergeleitet",
	"howToBuildContract": "Um das Dokument korrekt auszufüllen, müssen Sie eine ausfüllbare PDF erstellen. Drücken Sie auf die Hilfe-Taste unten, um zu sehen, wie Sie vorgehen müssen.",
	"contractType": "Typ",
	"createDocument": "Dokument anlegen",
	"upload": "Hochladen",
	"bidUploadInfo": "Nachdem Sie die unterzeichnete Kaufbestätigung hochgeladen haben, wird das Wohnhaus als verkauft gekennzeichnet",
	"onSoldStatusDialog": "Um das Wohnhaus als verkauft zu kennzeichnen, müssen Sie die unterzeichnete Kaufbestätigung hochladen.",
	"excludingVat": "exkl. MwSt.",
	"includingVat": "inkl. MwSt.",
	"priceOnRequest": "Preis nach Anfrage",
	"priceFrom": "Preis ab",
	"pricePerMonth": "Preis pro Monat",
	"salePrice": "Verkaufspreis",
	"priceMargin": "Aufschlag",
	"purchasePrice": "Einkaufspreis",
	"readMore": "Mehr lesen",
	"upsaleRequestTemplate": "Ich wünsche Hilfe mit \"{0}\"",
	"publishedFrom": "Veröffentlicht von",
	"publishedTo": "Veröffentlicht bis",
	"customerService": "Kundenservice",
	"residentialTemplateExplanation": "Durch das Anfertigen von Wohnhaustypen müssen Sie die gleiche Grundlage nicht mehrmals pflegen. Sie können alle Wohnhäuser/Wohnungen, die auf diesem Wohnhaustyp basieren, einfach und schnell aktualisieren, während Sie gleichzeitig die Möglichkeit behalten, die Wohnhäuser/Wohnungen individuell auf Projektebene zu ändern.",
	"unitTemplateExplanation": "Durch das Anfertigen von Einheitentypen müssen Sie die gleiche Grundlage nicht mehrmals pflegen. Sie können alle Einheiten, die auf diesem Typ basieren, einfach und schnell aktualisieren, während Sie gleichzeitig die Möglichkeit behalten, die Einheiten individuell auf Projektebene zu ändern.",
	"product": "Produkt | Produkte",
	"createProduct": "Produkt anlegen",
	"allProducts": "Alle Produkte",
	"category": "Kategorie | Kategorien",
	"productCategorySample": "z.B. Fußboden, Parkett, Heizung, Lüftung, Sanitär",
	"more": "Mehr",
	"residentialStatusBidLock": "Sie haben \"Bieten\" aktiviert und können den Status in oder von \"verkauft\" nicht manuell ändern. Unter Wohnhaus können Sie den Status ändern",
	"noAnalyticsCollected": "Wir haben noch nicht genug Daten gesammelt. Sie werden hier eine Statistik sehen, sobald die Website besucht wird.",
	"sendEmail": "E-Mail senden",
	"customThemeActiveMessage": "Sie verwenden eine personalisierte Vorlage von diesem Projekt.",
	"validDnsSetupMessage": "Die Domäne ist nicht richtig erstellt - überprüfen Sie die Anweisungen unten und versuchen Sie es erneut.",
	"siteSettings": "Seite Einstellungen",
	"showOpenhouseOnRequestIfNone": "\"@:openhouseOnRequest\" anzeigen, wenn keine Besichtigungen geplant sind",
	"showLeadUpsell": "\"@:contactUpsell\" anzeigen für das Interessentenformular",
	"showShopOnResidentialPage": "Shop für optionale Ausstattung auf Einheitenseiten anzeigen",
	"showShop": "Shop für optionale Ausstattung anzeigen",
	"typeHere": "Hier schreiben",
	"shopCategoryExamples": "z.B. Küche, Bad",
	"shopSubcategoryExamples": "z.B. Fliesen, Einrichtung",
	"shopAddProduct": "Produkt auswählen",
	"shopAddCategory": "@:addCategory",
	"shopAddSubcategory": "Unterkategorie hinzufügen",
	"lookingForAProductAdd": "Finden Sie nicht das Produkt, nach dem Sie suchen?",
	"hideSold": "Verkaufte ausblenden",
	"assetManagerTitleBrochureDirect": "Prospekt herunterladen",
	"assetManagerTitleBrochureLead": "Prospekt herunterladen",
	"assetManagerTitleBrochureRequest": "Prospekt zugesandt bekommen",
	"assetManagerActionBrochureLead": "Prospekt herunterladen",
	"assetManagerActionBrochureRequest": "Senden",
	"assetManagerFeedbackBrochureRequest": "Ihnen wird in Kürze der Prospekt zugesandt.",
	"assetManagerCommentBrochureRequest": "Ich möchte den Prospekt zugesandt bekommen.",
	"brochureDownloadStrategyLabel": "Auswählen, wie der Prospekt heruntergeladen werden kann",
	"brochureDownloadStrategyDirect": "Direkt herunterladen",
	"brochureDownloadStrategyLead": "Zuerst Interesse melden",
	"brochureDownloadStrategyRequest": "Anfrage senden / Manuell versenden",
	"selected": "Ausgewählt",
	"shopDefaultProduct": "Standardlieferung",
	"shopProductUpgrade": "Upgrade-Möglichkeiten",
	"totalPrice": "Gesamtpreis",
	"shopContactSellerForOtherInquiries": "Bei weiteren Wünschen wenden Sie sich bitte an den Verkäufer",
	"shopNotAvailableSize": "Für das optimale Erlebnis des Shops für optionale Ausstattung öffnen Sie diese Seite bitte auf einem größeren Bildschirm",
	"gotoItem": "Gehe zu {0}",
	"activate": "Aktivieren",
	"whatDoYouWantToCopy": "Was soll kopiert werden?",
	"helpTools": "Handbücher/Guides",
	"needHelp": "Hilfe",
	"title": "Titel",
	"nearbyarea": "Bereich",
	"shopSync": "Kopieren",
	"shopSyncAll": "Gesamter Shop für optionale Ausstattung",
	"badge-bid": "@:bid",
	"badge-comment": "@:comment",
	"badge-openhouse": "@:openhouse",
	"badge-shopcontract": "@:addonShop",
	"badge-lead": "@:lead",
	"badge-purchase": "@:addonPurchase",
	"badge-task": "Aufgabe",
	"badge-customcontract": "@:contract",
	"prepareContract": "Bereitstellung des Vertrags",
	"prepareShop": "Bereitstellung der optionalen Ausstattung",
	"preparePurchaseContract": "Bereitstellung des Kaufvertrags",
	"buyer": "Käufer | Käufer",
	"sendToSigning": "Zur Unterzeichnung senden",
	"deadline": "Frist",
	"shopLink": "Link zum Shop für optionale Ausstattung (Präsentation)",
	"shopLinkWithSelection": "Link zum Shop für optionale Ausstattung (Auswahl und Unterzeichnung)",
	"shopSigning": "Unterzeichnung",
	"shopConfirmation": "Bestätigung",
	"shopIntro": "Einleitung",
	"shopSummary": "Zusammenfassung",
	"shopSignedMessage": "Ihr Vertrag über optionale Ausstattung wurde unterzeichnet und gesendet. Sie werden in Kürze eine E-Mail mit der Bestätigung erhalten. Sobald Ihr Vertrag über optionale Ausstattung bearbeitet wurde, erhalten Sie per E-Mail eine Antwort.",
	"shopSigningNotReady": "Unterzeichnen ist noch nicht möglich; Sie werden informiert, sobald Sie unterzeichnen können",
	"startShopContract": "Prozess für optionale Ausstattung starten",
	"shopReadyTitle": "Ihr Shop für optionale Ausstattung ist bereit",
	"shopReadyMessage": "Sie können jetzt die optionale Ausstattung für Ihr Wohnhaus auswählen. \nUnterzeichnen ist noch nicht möglich, aber Sie können jetzt schon Ihre erste Auswahl treffen. Sobald das Unterzeichnen möglich ist, werden Sie informiert.",
	"shopWelcomeMessage": "Willkommen in Ihrem Shop für optionale Ausstattung - hier können Sie die optionale Ausstattung für Ihr Wohnhaus auswählen.\n\nSobald Sie Ihre Auswahl getroffen haben, können Sie zur Zusammenfassung weitergehen und dort Ihre Bestellung unterzeichnen.",
	"shopUnsignedMessage": "Sie können jetzt Ihre optionale Ausstattung auswählen und unterzeichnen",
	"gotoShop": "Zum Shop für optionale Ausstattung gehen",
	"shopContractStatusReady": "Bereit (zum Käufer gesendet)",
	"shopContractStatusDraft": "Kladde",
	"shopContractStatusUnsigned": "Warte auf Signatur",
	"shopContractStatusSigned": "@:signed",
	"shopContractStatusExpired": "Die Frist ist abgelaufen",
	"shopContractNotSold": "Dieses Wohnhaus ist noch nicht verkauft. Sobald sich der Status des Wohnhauses in verkauft ändert, können Sie mit dem Unterzeichnungsprozess beginnen.",
	"shopContractAttachment": "Anlage (wird vom Käufer unterzeichnet)",
	"shopContractStartRequirements": "Bevor Sie den Prozess für optionale Ausstattung starten, müssen Sie einen Käufer eingeben. \nBitte speichern Sie Ihre Änderungen. ",
	"shopContractRememberSignableFrom": "Bitte setzen Sie \"@:signableFrom\", sodass der Käufer die Bestellung der optionalen Ausstattung unterzeichnen kann.",
	"file": "Datei | Dateien",
	"import": "Importieren",
	"productImport": "Produkt Import",
	"importedXItem": "{count} {item} importiert",
	"importFailedResolveIssues": "Der Import konnte nicht durchgeführt werden. Unten finden Sie eine Übersicht über die aufgetretenen Fehler. Bitte korrigieren Sie diese und versuchen Sie es erneut.",
	"pressButtonBelowToGetStarted": "Drücken Sie auf die Taste unten, um loszulegen",
	"signingDeadlineIs": "Die Unterzeichnungsfrist ist",
	"shopLinkPresentation": "Präsentation",
	"shopLinkSigning": "Auswahl / Unterzeichnung (für den Käufer)",
	"export": "Exportieren",
	"page": "Seite | Seiten",
	"tourGetStartedSingleShop": "So legen Sie los mit: Einzelmodul - Optionale Ausstattung ",
	"tourHowToAddPartDeadlinesShop": "So setzen Sie Teilfristen für die Unterzeichnung der optionalen Ausstattung",
	"tourHowIfOrIfNotShopWork": "So funktioniert es, wenn / wenn kein Prozess für optionale Ausstattung",
	"tourGetStartedShop": "So legen Sie los mit: Shop für optionale Ausstattung",
	"tourGetStartedShopContract": "So legen Sie los mit: Digitale Unterzeichnung der optionalen Ausstattung",
	"tourTaskManagement": "So funktioniert die Aufgabenverwaltung im Kundenmanagementsystem",
	"tourGetStartedPurchaseContract": "So legen Sie los mit: Digitaler Kaufvertrag",
	"tourGetStartedBidContract": "So legen Sie los mit: Digitaler Gebotsprozess",
	"tourGetStartedSingleBidContract": "So legen Sie los mit: Einzelmodul - Digitaler Gebotsprozess ",
	"tourHowToEditProjectResidentials": "So bearbeiten Sie das Projekt/Wohnhäuser",
	"tourHowToCreateFlatfinder": "So bearbeiten Sie / legen Sie die Wohnhausauswahl an",
	"tourHowToCreateResidentialTemplate": "So bearbeiten Sie / legen Sie die Wohnhaustypen an",
	"tourHowToBuildContract": "So erstellen Sie das Gebotsformular / die Kaufbestätigung ",
	"tourHowDashboardWorks": "So funktioniert das Dashboard",
	"tourHowToCreateProjectTeaser": "So legen Sie einen Teaser an (Kommt zum Verkauf)",
	"tourRoles": "So verwalten Sie Benutzer ",
	"tourHowToPublish": "So veröffentlichen Sie das Projekt unter einer Domäne",
	"tourTitleAdditionalServices": "Brauchen Sie Hilfe?",
	"tourHowToBuildPDF": "So erstellen Sie eine ausfüllbare PDF",
	"tourHowToHandleLead": "So verwalten Sie Interessenten",
	"tourHowToHandleOpenhouse": "So verwalten Sie Besichtigungen",
	"howManyUnitsOnProject": "Wie vielen Einheiten sollen insgesamt verkauft werden?",
	"attachmentIsEncryptedWithSSN": "Bitte beachten Sie, dass die Anlage verschlüsselt ist - das Passwort ist Ihre Personenkennziffer. Das Dokument, das Sie erhalten haben, ist eine Kopie; das Original befindet sich beim Verkäufer.",
	"contractApprovalWarning": "Bitte kontrollieren Sie vor der Genehmigung den Inhalt des Dokuments.",
	"addon:project": "Projekt",
	"addon:project:presentation": "Projekt-/Wohnhausseite",
	"addon:project:bid": "Kaufmodul",
	"addon:project:purchase": "Kaufvertrag",
	"addon:project:shop": "Optionale Ausstattung - Präsentation",
	"addon:project:shop-purchase": "Optionale Ausstattung - Verkauf",
	"addon:custom-contract": "Unterzeichnung des Dokuments",
	"addon:api": "API-Zugriff",
	"addon:flatfinder": "Wohnhausauswahl",
	"addon": "Modul | Module",
	"fixedPrice": "Festpreis",
	"areaPrice": "Preis pro m²",
	"customerPrice": "Preis für Kunden",
	"establishment": "Errichtung",
	"ownershipType:leasehold": "Schuldverschreibung",
	"ownershipType:freehold": "Eigentümer (Eigenbesitz)",
	"ownershipType:other": "Sonstiges",
	"ownershipType:partOwnership": "Anteil",
	"ownershipType:collectiveOwnership": "Aktie ",
	"plotOwnershipType:leasehold": "Schuldverschreibung",
	"plotOwnershipType:freehold": "Eigentümer (Eigenbesitz)",
	"plotOwnershipType:other": "Sonstiges",
	"plotOwnershipType:partOwnership": "Anteil",
	"plotOwnershipType:collectiveOwnership": "Aktie ",
	"subpage": "Unterseite | Unterseiten",
	"orderItem": "{item} bestellen",
	"ShopPurchaseRequiresShop": "Um \"Optionale Ausstattung - Verkauf\" nutzen zu können, benötigen Sie auch \"Optionale Ausstattung - Präsentation\"",
	"projectUnitLimitReached": "Sie können noch weitere {0} Einheiten für dieses Projekt anlegen. Um weitere Einheiten hinzuzufügen, müssen Sie auf eine größere Lizenz upgraden. Zum Upgraden wenden Sie sich bitte an support@kvass.no oder chatten Sie mit uns.",
	"slug": "Slug",
	"content": "Inhalt",
	"setArea": "Fläche festsetzen",
	"rounding:none": "Keine",
	"priceRounding": "Preisrundung",
	"exampleShort": "z.B.",
	"productOverrideMessage": "\"{value}\" ist mit einem Produktkatalog verknüpft. Drücken Sie auf \"Bearbeiten\", um einen eigenen Wert zu setzen.",
	"productOverrideReset": "Verknüpfen Sie \"{value}\" mit dem Produktkatalog - entfernen Sie den eigenen Wert.",
	"priceMissingArea": "Fläche fehlt",
	"total": "Gesamt",
	"instagramProfile": "Instagram-Profil",
	"numberOfPosts": "Anzahl Beiträge",
	"followUsOn": "Folgen Sie uns auf {value}",
	"customFieldsResidentialTemplatesLabel": "Präsentation von Wohnhaustypen",
	"signableFrom": "Kann unterzeichnet werden von",
	"setPartialDeadlines": "Teilfristen setzen",
	"removePartialDeadlines": "Teilfristen entfernen",
	"partialDeadline": "Teilfrist | Teilfristen",
	"madeBy": "Ein Dienst von",
	"gotoSummary": "Zur Zusammenfassung gehen",
	"selectCategoriesToSign": "Kategorien auswählen, die unterzeichnet werden sollen",
	"shopBuyerStatusDraft": "Unterzeichnen noch nicht möglich",
	"shopBuyerStatusUnsigned": "Zum Unterzeichnen bereit",
	"wholeShop": "Gesamter Shop für optionale Ausstattung",
	"company": "Unternehmen",
	"companyName": "Unternehmensname",
	"businessSite": "Unternehmensseite",
	"private": "Privat",
	"footer": "Fußzeile",
	"creatingContracts": "Dokumente zum Unterzeichnen werden erstellt, bitte warten Sie einen Augenblick",
	"pressButtonBelowToGoToShop": "Drücken Sie auf die Taste unten, um zu Ihrem Shop für optionale Ausstattung zu gelangen",
	"youHaveNewShopInfo": "Sie haben aktualisierte Informationen in Ihrem Shop für optionale Ausstattung erhalten",
	"youHaveAnUpcomingDeadline": "Sie haben eine Frist, die sich nähert",
	"hiName": "Hallo, {name}",
	"shopPresentationSidebarDescriptionTitle": "Erstellen Sie Ihr Wohnhaus",
	"shopPresentationSidebarDescriptionContent": "Hier können Sie sehen, welche Modernisierungen für dieses Wohnhaus verfügbar sind.",
	"signingLoadingMessage": "Wir stellen Ihre Dokumente fertig - bitte warten Sie einen Augenblick (Dauer ca. 15-30 Sek.)",
	"tag": "Tag | Tags",
	"noTags": "Keine Tags",
	"youHaveANewMessageFrom": "Sie haben eine neue Nachricht von {from}",
	"nameSays": "{name} sagt",
	"gotoDialog": "Zum Dialog gehen",
	"youCanAswerThisEmail": "Sie können auf diese E-Mail antworten - Ihre Antwort wird weitergeleitet",
	"send": "Senden",
	"note": "Notiz",
	"thisMessageWasSentWith": "Diese Nachricht wurde gesendet mit",
	"addCategory": "Kategorie hinzufügen",
	"availableIf": "Verfügbar, wenn",
	"availableIfNot": "Nicht verfügbar, wenn",
	"xActiveConditions": "Keine aktiven Regeln | {count} aktive Regel | {count} aktive Regeln",
	"setConditions": "Regeln setzen",
	"displayName": "Anzeigename",
	"useDisplayName": "Verwenden Sie einen anderen Anzeigenamen",
	"statisticsVisitors": "Besucherzahl",
	"statisticsSalesResidentials": "Verkaufszahlen",
	"statisticsSalesShop": "Verkaufszahlen (Optionale Ausstattung)",
	"externalUserAlert": "Sie versuchen, einen Benutzer außerhalb Ihrer Organisation ({domain}) hinzuzufügen. \nDer Betreffende erhält: \n{value} \n\nBitte bestätigen Sie, dass Sie {email} hinzufügen möchten.",
	"sendMessageToSeller": "Nachricht an Verkäufer senden",
	"sendMessageToSellerButtonLabel": "Haben Sie Fragen? – Kontaktieren Sie uns hier",
	"sent": "Gesendet",
	"member": "Mitglied | Mitglieder",
	"editTask": "Aufgabe bearbeiten",
	"createTask": "Aufgabe anlegen",
	"undone": "Offen",
	"noDescription": "Keine Beschreibung",
	"noDeadline": "Keine Frist",
	"youHaveANewTask": "Sie haben eine neue Aufgabe bekommen",
	"gotoTask": "Zur Aufgabe gehen",
	"gotoDashboard": "Zum Dashboard gehen",
	"youHaveXTaskDueIn": "Sie haben {count} Aufgabe mit Frist bis zum {dueby} | Sie haben {count} Aufgaben mit Frist bis zum {dueby}",
	"projectAdditionalFieldsDescriptionFooter": "Fußzeile hinzufügen, z.B.: Über uns / Anfahrt",
	"projectAdditionalFieldsDescriptionResidentailTemplate": "Wählen Sie aus Ihren Wohnhaustypen die, die Sie auf der Projektseite präsentieren möchten.",
	"projectAdditionalFieldsDescriptionArea": "Geben Sie Texte und Bilder ein, um das Gebiet u.Ä. zu präsentieren.",
	"projectAdditionalFieldsDescriptionSubpage": "Erstellen Sie Unterseiten, auf denen Sie Bilder und Text präsentieren können. Sie können die Unterseiten verlinken, indem Sie in den Textfeldern Links hinzufügen.",
	"clone": "Duplizieren",
	"type": "Typ | Typen",
	"filter": "Filtern",
	"noShopDefaultProduct": "Keine Standardlieferung",
	"customer": "Kunde | Kunden",
	"purchaseManuallyConfirmed": "Der hochgeladene Kaufvertrag ist unterzeichnet",
	"cannotAddMoreUsers": "Sie können keine weiteren Benutzer hinzufügen. Bitte wenden Sie sich an den Support, wenn Sie weitere hinzufügen möchten.",
	"videoPreviewMessage": "{type} Link hinzufügen für die Video-Vorschau",
	"videoLink": "{type} Link",
	"profile": "Profil | Profile",
	"redirectToExternalSite": "Weiterleiten an externe Seite",
	"residentialRedirectUrlExample": "z.B. https://...",
	"optional": "optional",
	"residentialRedirectUrlExplanation": "Verwenden Sie dieses Feld, wenn Sie Besucher auf eine andere Website weiterleiten wollen",
	"createdAt": "Angelegt am",
	"overview": "Übersicht",
	"mediaTypeComboMessage": "Oder drücken Sie auf die Taste, um aus anderen Medientypen auszuwählen",
	"mediaTypeDefaultMessage": "Drücken Sie auf die Taste unten, um andere Filtertypen hochzuladen",
	"userIsDeveloper": "Entwicklertools anzeigen",
	"noFloorplansSetup": "Keine Grundrisse erstellt",
	"mark": "Markieren",
	"createResidentialTemplateBeforeShop": "Um den Shop für optionale Ausstattung anlegen zu können, müssen Sie erst den Wohnhaustyp anlegen",
	"noShopsetsSetup": "Keine Pakete für optionale Ausstattung erstellt",
	"shopSet": "Paket für optionale Ausstattung | Pakete für optionale Ausstattung",
	"productsThatArePartOfSet": "Produkte im Paket für optionale Ausstattung",
	"manualSelectAndSkip": "Selbst wählen (Überspringen)",
	"event": "Ereignis | Ereignisse",
	"url": "URL",
	"enabled": "Aktiviert",
	"webhook": "Webhook | Webhooks",
	"createWebhook": "Webhook erstellen",
	"setting": "Einstellung | Einstellungen",
	"webhookSecret": "Geheim",
	"attempt": "Versuch",
	"of": "von",
	"log": "Protokoll | Protokolle",
	"noWebhooks": "Keine Webhooks",
	"webhookIsSystem": "Systemintegration",
	"noLogs": "Keine Protokolle",
	"token": "API-Schlüssel | API-Schlüssel",
	"createToken": "API-Schlüssel erstellen",
	"noTokens": "Keine API-Schlüssel",
	"acl": "Zugriffskontrolle",
	"tokenSecretMessage": "Bitte kopieren Sie diesen Schlüssel und hinterlegen Sie ihn an einem sicheren Ort. Aus Sicherheitsgründen zeigen wir diesen nur ein einziges Mal.",
	"shopContractNoticeSellerApproachingSubject": "Die Unterzeichnungsfrist des Käufers läuft ab",
	"shopContractNoticeSellerOverdueSubject": "Die Unterzeichnungsfrist des Käufers ist abgelaufen",
	"shopContractNoticeSellerExpires": "Der Käufer hat den Vertrag über optionale Ausstattung nicht unterzeichnet.",
	"unit": "Einheit | Einheiten",
	"noUnits": "Keine Einheiten",
	"noMoreUnits": "Keine weiteren Einheiten",
	"createUnit": "Einheit anlegen",
	"unitTemplate": "Einheitentyp | Einheitentypen",
	"residentialSaleWithBroker": "Mit Makler verkaufen ",
	"residentialSaleSelf": "Digitaler Vertragsabschluss",
	"residentialSaleWithBrokerBidHelperText": "Hier können Sie einen optionalen Link hinzufügen, mit dem Sie die Einheit verkaufen können. Der Link ist \nüber die \"Kauf\"-Taste auf der Einheitenseite verfügbar. ",
	"bidContractMissingShowBidUrl": "Die Verkaufsbestätigung ist nicht hochgeladen. \nDie Kaufbestätigung des Projekts hochladen, um die Funktionen für den digitalen Verkauf zu aktivieren.",
	"residentialSaleBidHelperText": "Mit diesem Link können Sie die Einheit digital verkaufen. \nDer Link ist über die \"Kauf\"-Taste auf der Einheitenseite verfügbar, aber der Link kann auch von hier kopiert werden. \nEin Kauf, der über diesen Link durchgeführt wird, erscheint im Dashboard des Verkäufers. ",
	"residentialSaleBidActiveHelperText": "Das Kaufmodul ist aktiviert. Wenn Sie eine andere Verkaufsmethode nutzen wollen, können Sie eine Maklerintegration aktivieren. ",
	"pressButtonBelowToGoToShopNoticeSeller": "Drücken Sie auf die Taste für weitere Informationen über den Shop für optionale Ausstattung ",
	"copy": "Kopieren",
	"copied": "Kopiert",
	"paywallContentFieldUsedByFollowingModules ": "Diese Felder sind für aktivierte Module nicht relevant",
	"paywallContentPressButtonToReadMore": "Drücken Sie auf die Taste, um mehr zu lesen oder das Modul zu aktivieren | Drücken Sie auf eine der Tasten, um mehr zu lesen oder das Modul zu aktivieren",
	"deliveryLogClickLabel": "{resource} geliefert - geöffnet",
	"deliveryLogOpenLabel": "{resource} geliefert - gesehen",
	"deliveryLogFailedLabel": "{resource} konnte nicht geliefert werden",
	"deliveryLogDeliveredLabel": "{resource} geliefert - nicht geöffnet",
	"deliveryLogUnknownLabel": "{resource} - wartet auf Lieferung",
	"invite": "Einladung",
	"info": "Info",
	"oneOrMoreDeadlinesExpired": "Eine oder mehrere Fristen sind abgelaufen",
	"projectShopsOpenByBuyer": "Geöffnet vom Käufer",
	"contractStatusNotAccepted": "Wartet auf Genehmigung",
	"noMoreShops": "Keine weiteren Shops für optionale Ausstattung",
	"projectShopsCreateResidentialsWarning": "Legen Sie Einheiten an, bevor Sie Shops für optionale Ausstattung erstellen.",
	"projectShopsEditShop": "Nicht angelegt",
	"projectShopsCreateResidentials": "Einheit anlegen",
	"projectShopsNotSold": "Nicht verkauft",
	"projectSaleBrokerGuide1": "Unter \"Verkauf\" bei den Einheiten können Sie Links zu den Kaufprozessen von Maklerlösungen hinzufügen",
	"projectSaleBrokerGuide2": "z.B. vom sicheren Bieten über tryggbudgivning.",
	"projectSaleBrokerGuide3": "Auf Wunsch kann auch eine Integration mit Maklerlösungen bestellt werden.",
	"residentialsFlatfinderGuideInfo": "Wenn Sie Einheiten hinzugefügt haben, können Sie auf Wunsch Wohnhausauswahlen anlegen. Dazu gehen Sie zum Projekt-Dashboard und klicken auf \"Anlegen\" und anschließend auf \"Wohnhausauswahl\".",
	"shop": "Shop für optionale Ausstattung | Shops für optionale Ausstattung",
	"devtools": "Entwicklertools",
	"embed": "Einbettung",
	"imageRecommendation": "Empfohlenes Bildformat: {width} {symbol} {height}",
	"height": "Höhe",
	"width": "Breite",
	"imageFlatfinderRecommendation": "Kann frei gewählt werden - die Ansicht passt sich entsprechend an",
	"consentRequiredTitle": "Notwendige Cookies",
	"consentRequiredDescription": "Notwendige Cookies nehmen grundlegende Funktionen wie Seitennavigation und Zugriff auf sichere Bereiche der Website wahr. Die Website kann ohne diese Cookies nicht optimal funktionieren.",
	"consentStatisticsTitle": "Statistik",
	"consentStatisticsDescription": "Analytische Cookies helfen uns, unsere Website zu verbessern, indem sie statistische Informationen zur Nutzung sammeln und darüber berichten. Die Informationen werden anonymisiert.",
	"consentMarketingTitle": "Marketing",
	"consentMarketingDescription": "Marketing-Cookies werden genutzt, um den Inhalt sozialer Medien und Werbeanzeigen einzublenden, die für den Nutzer relevanter und auf dessen Interessen abgestimmt sind.",
	"consentDialogTitle": "Stimmen Sie der Verwendung von Cookies zu?",
	"consentDialogDescription": "Wir verwenden Cookies, um Ihnen ein bestmögliches Nutzererlebnis zu ermöglichen. Cookies werden verwendet, um die Nutzung der Website zu analysieren, die Website zu verbessern sowie Inhalt und Werbung zu personalisieren.\n  Weitere Informationen finden Sie in unserer <a href=\"/api/legal/privacy/tenant\">Datenschutzerklärung</a>.",
	"consentDialogLabelAccept": "Ja, alle akzeptieren",
	"consentDialogLabelAcceptAll": "Alle akzeptieren",
	"consentDialogLabelConfirm": "Bestätigen",
	"consentDialogLabelConfigure": "Nein, Einstellungen ändern",
	"editConsents": "Datenschutzeinstellungen ändern",
	"noAttachments": "Keine Anlagen",
	"tourVideoUpload": "Wie wird ein Video hinzugefügt",
	"language": "Sprache",
	"textOverwriteWarning": "Wenn {value} fehlt, wird folgender Text angezeigt:",
	"cookieVideoBlockMessage": "Um das Video abzuspielen, benötigen wir Ihre Einwilligung",
	"sizes": "Größen",
	"productLink": "Produktlink",
	"showDefaultSelection": "Standardauswahl anzeigen",
	"noUpgradesSelected": "Keine Modernisierungen ausgewählt",
	"setValue": "{value} setzen ...",
	"internalName": "Interner Name",
	"useInternalName": "Eigenen internen Namen verwenden",
	"shopWelcomeText": "Begrüßungstext",
	"scopedProduct": "Nicht aus dem Katalog",
	"contactImport": "Kontakt Import",
	"importTemplateDescription": "für den Import",
	"socialMedia": "Soziale Medien",
	"downloadTemplate": "Vorlage herunterladen",
	"projectCreationVideoMessage": "Finden Sie heraus, wie Sie Kvass bei {type} verwenden können - sehen Sie sich den Film oben an!",
	"singleResidential": "Reihenhaus | Reihenhäuser",
	"myPage": "Meine Seite",
	"contractStatusNotAcceptedBySeller": "Warte auf Genehmigung vom Verkäufer",
	"contractStatusAcceptedBySeller": "Genehmigt vom Verkäufer",
	"followSoMeTitle": "Folgen Sie uns auf den sozialen Medien",
	"projectAdditionalFieldsSoMeDescriptionArea": "Fügen Sie einen Link zu Ihren sozialen Medien hinzu und zeigen Sie diese auf der Website.",
	"projectAdditionalFieldsSeoDescriptionArea": "Überschreiben Sie die SEO-Anpassungen (Titel und Beschreibung). Hier lernen Sie mehr",
	"projectAdditionalFieldsFacebookAdsDescriptionArea": "Felder für das Layout der Facebook-Werbung einstellen",
	"SEO": "SEO",
	"facebookAds": "Facebook-Werbung",
	"back": "Zurück",
	"newFolder": "Neuer Ordner",
	"dragFolderOrDrop": "Ordner hierher ziehen oder zum Hochladen <b>drücken</b>",
	"managementOperationMaintenance": "OAM-Dokumentation",
	"residentialAccount": "Meine Seite",
	"clickToFilter": "Zum Filtern drücken",
	"imagesOnlyAsIllustration": "Bilder dienen Illustrationszwecken",
	"foundXResults": "{0} Ergebnisse gefunden",
	"active": "Aktiv",
	"inactive": "Inaktiv",
	"contract": "Vertrag | Verträge",
	"buyConfirmation": "Kauf unterzeichnet",
	"residentialAccountLogin": "Anmelden auf Meine Seite",
	"loginPressButton": "Zum Anmelden auf die Taste unten klicken",
	"loginVisitLink": "Oder diesen Link in den Browser einfügen",
	"loginLinkExpires": "Dieser Link läuft in {expires} Minuten aus",
	"loginGetNewLink": "einen neuen Link zugesendet bekommen",
	"residentialAccountYourUnits": "Ihre Einheiten",
	"residentialAccountLoginTooSeeUnits": "Anmelden, um Ihre Einheiten zu sehen",
	"loginEnterValue": "Geben Sie {value} ein und drücken Sie auf \"Abschließen\".",
	"loginSuccessMessage": "Sie sollten jetzt eine E-Mail mit weiteren Informationen erhalten haben",
	"sortOn": "Sortieren nach",
	"highLow": "hoch-niedrig",
	"lowHigh": "niedrig-hoch",
	"syncUser": "Benutzerdaten abrufen",
	"lowHighLetter": "Z - A",
	"highLowLetter": "A - Z",
	"addFilter": "Filter hinzufügen",
	"resetFilter": "Filter zurücksetzen",
	"validTo": "Gültig bis",
	"registrationComplete": "Sie haben die Registrierung abgeschlossen.",
	"clickToLogin": "Zum Anmelden die Taste unten drücken.",
	"welcomeToKvass": "Willkommen bei Kvass",
	"enterPasswordAndClickComplete": "Geben Sie Ihr Passwort ein und drücken Sie auf \"Abschließen\".",
	"tokenExpiredRequestNew": "Ihre Einladung ist abgelaufen, bitten Sie um eine neue. ",
	"author": "Autor",
	"post": "Beitrag | Beiträge",
	"createPost": "Beitrag anlegen",
	"shopShort": "Optionale Ausstattung",
	"developer": "Entwickler",
	"access": "Zugriff | Zugriffe",
	"supplier": "Lieferant | Lieferanten",
	"technicalDescription": "Technische Beschreibung",
	"addTechnicalDescription": "Technische Beschreibung hinzufügen",
	"switchTenant": "Konto wechseln",
	"leadMessageSent": "Danke für Ihr Interesse. In Kürze wird sich ein Kundenbearbeiter mit Ihnen in Verbindung setzen.",
	"externalUserAlertAdmin": "Zugriff auf die Projekte und dazugehörige Daten in der Lösung ",
	"externalUserAlertNoRights": "Kein Zugriff auf die Daten in der Lösung",
	"externalUserAlertRoles": "Rolle als: {roles} ",
	"externalUserAlertAccess": "Zugriff auf: {access} ",
	"shopPresentationPreviewAlert": "Dem Shop für optionale Ausstattung fehlen Daten. \nDie Vorschau ist verfügbar, wenn der Shop für optionale Ausstattung erstellt ist.",
	"addon:project:complaint": "Reklamation",
	"archived": "Archiviert",
	"showArchived": "Archivierte anzeigen",
	"archive": "Archivieren",
	"integrationCompliance": "Es wird hiermit bestätigt, dass Kvass instruiert wird, die personenbezogenen Daten über unsere Integration zu übertragen und dass ich/wir eigenständig verantwortlich bin/sind für die Bearbeitung der Übertragung und die weitere Bearbeitung der personenbezogenen Daten.",
	"documentSigned": "Dokument unterzeichnet",
	"documentSignedConfirmationMessage": "Das Dokument wurde unterzeichnet und gesendet. Sie werden in Kürze eine E-Mail mit der Bestätigung erhalten. Sobald Ihr Dokument bearbeitet wurde, erhalten Sie per E-Mail eine Antwort.",
	"integrationNotificationEmailBody": "Wir möchten darüber informieren, dass ein neuer {type} dem Konto: {account} hinzugefügt wurde.",
	"integrationNotificationEmailBodyReminder": "Bitte sorgen Sie dafür, dass die nötigen Datenverarbeitungsverträge mit allen Unterlieferanten vorliegen.",
	"integrationNotificationEmailSubject": "Ein neuer {type} mit dem Namen \"{name}\" wurde hinzugefügt",
	"noContracts": "Keine Verträge",
	"awaitingSignature": "Warte auf Signatur",
	"residentialAddressControl": "Adresse prüfen",
	"residentialAccountNotSold": "Diese Einheit ist noch nicht verkauft. Sobald sich der Status der Einheit in verkauft ändert, sind die Einstellungen verfügbar.",
	"createContract": "Vertrag anlegen",
	"lastUpdated": "Zuletzt aktualisiert",
	"complaint": "Reklamation | Reklamationen",
	"lookingForAContactAdd": "Finden Sie nicht den Kontakt, nach dem Sie suchen?",
	"orderConfirmationCondition": "Alle Produkte, die aktiviert werden, haben eine Bindefrist von {lockInPeriod}. Die Produkte werden automatisch laufend um {noticePeriod} verlängert, sofern die Verlängerung nicht schriftlich von einer der Parteien vor Ablauf der laufenden Periode gekündigt wird.",
	"orderConfirmationConditionShort": "{lockInPeriod} Bindefrist / wird automatisch verlängert nach {noticePeriod} ",
	"orderConfirmation": "Auftragsbestätigung",
	"unitLimitMessage": "Sie können bis zu {limit} Einheiten anlegen.",
	"taskStatusUndone": "Offen",
	"taskStatusDone": "Abgeschlossen",
	"taskStatusRejected": "Abgelehnt",
	"taskStatusRequest": "Anfrage",
	"subjectArea": "Fachbereich",
	"showCompleted": "Abgeschlossene anzeigen",
	"complaintRequest": "Nachfrage | Nachfragen",
	"complaintTermsOfRequest": "Worum geht es?",
	"complaintAddSelection": "Markierung hinzufügen",
	"complaintRegister": "Reklamation einsenden",
	"companyOrder": "{company} hat den Dienst bestellt",
	"activatedBy": "Der Dienst wurde von {user} aktiviert",
	"orderConfirmationAttached": "Im Anhang finden Sie eine Auftragsbestätigung.",
	"install": "Installieren",
	"uninstall": "Deinstallieren",
	"installed": "Installiert",
	"residentialAccountPublishAlert": "Bei Veröffentlichung erhalten die Eigentümer eine Benachrichtigung, sich auf Meine Seite anzumelden.",
	"reset": "Zurücksetzen",
	"showLess": "Weniger anzeigen",
	"residentialAccountAccess": "Sie haben Zugriff auf Meine Seite erhalten",
	"taskChangeStatus": "{type} '{title}' ist {status}",
	"taskChangeStatusSubject": "{type} ist {status}",
	"residentialAccountSettingsShowShop": "Den Shop für optionale Ausstattung verfügbar machen unter Meine Seite",
	"residentialAccountSettingsShowShopSublabel": "Zusammenfassung, Fristen und sonstigen relevanten Inhalt anzeigen",
	"residentialAccountSettingsShowShopDocuments": "Dokumentation für bestellte optionale Ausstattung anzeigen unter Meine Seite",
	"residentialAccountSettingsShowShopDocumentsSublabel": "Dokumentation wird autogeneriert und unter OAM-Inhalt angezeigt",
	"residentialAccountSettingsShowResidentialDocuments": "Dokumente der Einheit anzeigen unter Meine Seite",
	"residentialAccountSettingsShowResidentialDocumentsSublabel": "Verträge, Grundrisse, Anlagen und Prospekte",
	"redirectUrl": "Adresse für Weiterleitung",
	"contactSeller": "Kontakt Verkäufer",
	"importFromCatalog": "Aus dem Katalog bereitstellen",
	"reject": "Ablehnen",
	"rejected": "Abgewiesen",
	"addon:project:flatfinder": "Wohnhausauswahl",
	"blockComplaints": "Den Erhalt von Reklamationen für diese Einheit blockieren",
	"unblockComplaints": "Das Blockieren von Reklamationen für diese Einheit aufheben",
	"complaintsBlocked": "Der Erhalt von Reklamationen für diese Einheit ist blockiert.",
	"taskStatusChangeToUndone": "in Bearbeitung",
	"taskStatusChangeToDone": "bearbeitet",
	"taskStatusChangeToRejected": "abgelehnt",
	"attachmentsSigningUploadWarning": "Vor dem Unterzeichnen müssen die Anlagen durchgelesen werden. \nAchtung! Die Anlagen sind im Hauptvertrag, der unterzeichnet wird, nicht enthalten.",
	"addonIsIncludedIn": "{addon} ist nicht in {in} enthalten",
	"tourHowToEmbedFlatfinder": "Wohnhausauswahl in externe Seiten einbetten",
	"helpers:roleManager": "Kann Projekt und Einheiten bearbeiten.",
	"helpers:roleSigningAuthority": "Kann Kauf genehmigen oder ablehnen.",
	"helpers:roleSalesman": "Bekommt Lesezugriff und wird als Ansprechpartner angezeigt.",
	"helpers:roleShopManager": "Kann Shops für optionale Ausstattung bearbeiten.",
	"helpers:roleShopSigningAuthority": "Kann Verträge über optionale Ausstattung genehmigen oder ablehnen.",
	"helpers:roleShopSalesman": "Bekommt Lesezugriff und wird als Ansprechpartner angezeigt.",
	"salesmanShopVisibility": "Kontaktinformationen der Kundenbearbeiter für die optionale Ausstattung werden im Unterzeichnungsprozess angezeigt",
	"productBundleContains": "Enthält folgende Produkte",
	"productBundle": "Produktpaket",
	"rent": "Vermietung",
	"saleType": "Verkaufstyp",
	"actionLabel:export": "Exportieren",
	"actionLabel:integration": "Integrationen",
	"action": "Aktion | Aktionen",
	"helpers:productBundle": "Ein Produktpaket ist eine Zusammensetzung aus mehreren Einzelprodukten. Sie können auswählen, welche Produkte enthalten sind, und der Endkunde kann das Produktpaket auswählen und die Informationen zu jedem Einzelprodukt sehen.",
	"office": "Büro",
	"integrationDpaRecommendation": "Vorschlag für Datenverarbeitungsvertrag",
	"ordinaryProductPrice": "Regulärer Produktpreis",
	"helpers:shopPricingMethodTitle:add": "Standard",
	"helpers:shopPricingMethodDescription:add": "Hier ist das Standardprodukt immer im Preis inbegriffen. Der Preis für Modernisierungen basiert auf dem regulären Produktpreis.",
	"helpers:shopPricingMethodTitle:diff": "Differenz",
	"helpers:shopPricingMethodDescription:diff": "Hier wird die Differenz zwischen dem Standardprodukt und der Modernisierung berechnet.",
	"selectMethod": "Methode auswählen",
	"priceCalculation": "Preisberechnung",
	"unitNumber": "Einheitennummer",
	"productUpdateAffectedProducts": "Die Änderungen beeinflussen die {products} Produktauswahl in den {shops} Shops für optionale Ausstattung",
	"upcomingLabel": "Kommt bald",
	"hide": "Ausblenden",
	"residentialAdditionalFieldsLabel": "Sonstige Felder, die hinzugefügt werden können:",
	"link": "Link | Links",
	"loggingIn": "Wird angemeldet",
	"setDueDates": "Fristen setzen",
	"complaintDescription": "Reklamation beschreiben",
	"helpers:managementOperationMaintenance": "OAM-Dokumente hier hochladen. Sämtliche Dokumente werden auf Meine Seite angezeigt. \nAußerdem haben Sie die Möglichkeit, die Dokumentation von der optionalen Ausstattung sowie von Grundrissen, Verträgen und Anlagen, die an die Einheit geknüpft sind, anzuzeigen (kann unter Einstellungen aktiviert werden).",
	"helpers:roleComplaintManager": "Empfange alle eingehenden Reklamationsfälle",
	"residentialAccountLoginCondition": "Die E-Mail-Adresse muss im System erfasst sein",
	"noComplaints": "Keine Reklamationen",
	"complaintDisclaimerConsent": "Ich bestätige, dass der Kunde bei einem Gutachten einer nicht berechtigten Reklamation mit unseren tatsächlich angefallenen Kosten belastet werden kann.",
	"complaintDisclaimerEdit": "Haftungsausschluss bearbeiten",
	"fdvControlLabel": "OAM wurde hochgeladen und kontrolliert",
	"fdvControlSublabel": "Kreuzen Sie an, wenn OAM komplett ist und kontrolliert wurde",
	"shopEditDueAtUnitNotSold": "Die Wohnhäuser sind noch nicht verkauft. Sobald sich der Status des Wohnhauses in verkauft ändert, können Sie Fristen setzen.",
	"confirmed": "Bestätigt",
	"control": "Kontrolle",
	"noMembers": "Keine Mitglieder",
	"complaintRoleMissing": "Rolle für den Umgang mit Reklamationen fehlt bei dem Projekt. ",
	"folder": "Ordner | Ordner",
	"helpers:complaintsList": "Über Meine Seite kann der Käufer Reklamationen einsenden; sämtliche Reklamationsfälle für diese Einheit werden hier angezeigt.",
	"completeTask": "Aufgabe abschließen",
	"changeStatus": "Status ändern",
	"confirmRequest": "Anfrage bestätigen",
	"rejectRequest": "Anfrage ablehnen",
	"setTaskToUndone": "Auf \"Offen\" zurücksetzen",
	"cannotEditMultipleResidentialFieldsSameSaleType": "Die Einheiten haben unterschiedliche Verkaufstypen und können deshalb nicht zusammen bearbeitet werden",
	"noContacts": "Keine Kontakte",
	"filterEmptyState": "Filter ändern und erneut versuchen",
	"invoice": "Rechnung",
	"receiver": "Empfänger | Empfänger",
	"addon:project:presentation:sublabel": "Erstellen Sie schnell eine Website mit der Wohnhausauswahl.",
	"addon:project:bid:sublabel": "Versenden und unterschreiben Sie den Kaufvertrag digital.",
	"addon:project:purchase:sublabel": "Verkaufen Sie einfach und digital über die Projekt-/Wohnhausseite.",
	"addon:project:shop:sublabel": "Optionale Ausstattung digital präsentieren über Projekt/-Wohnhausseite oder über einen separaten Link.",
	"addon:project:shop-purchase:sublabel": "Optionale Ausstattung digital verkaufen über Projekt/-Wohnhausseite, Meine Seite oder über einen separaten Link.",
	"addon:project:residential-account:sublabel": "Ihren Kunden Zugriff auf Meine Seite gewähren",
	"addon:project:residential-account": "Meine Seite (inkl. bei aktiver Projektlizenz)",
	"billing": "Rechnungsempfänger",
	"featuredResidentialPropertyHelper": "Diesen Schlüsselwert auf der Projektseite hervorheben",
	"viewProject": "Siehe Projekt",
	"unitSelectorIsEntry": "Haupt-Einheitenauswahl",
	"autocompleteAddressAlert": "Haben Sie noch keine Adresse? Den Cursor auf der Karte bewegen, um die Position zu setzen.",
	"externalFileDisclaimer": "Der Inhalt in den Dateien wurde nicht geprüft. Sie sind selbst verantwortlich für das Herunterladen externer Dateien.",
	"getMarkerPosition": "Position der Karte entnehmen",
	"createResource": "{resource} anlegen",
	"taskCommentsReceiverInfo": "Die Nachrichten werden an die Mitglieder gesendet und an den Anleger der Aufgabe",
	"createdBy": "Angelegt von",
	"openhouseEmptyState": "Eine Besichtigung anlegen und loslegen",
	"documentsEmptyState": "Mit dieser Einheit sind keine Dokumente verknüpft",
	"startShops": "Mehrere Shops für optionale Ausstattung starten",
	"noUnitTemplates": "Keine Einheitentypen",
	"unitTemplatesEmptyState": "Einen Einheitentyp anlegen und loslegen",
	"noApplicantShopContractTooltip": "Ein oder mehrere Shops für optionale Ausstattung sind zum Starten noch nicht bereit und werden ignoriert.",
	"noUsers": "Keine Benutzer",
	"usersEmptyState": "Einen Benutzer anlegen und loslegen",
	"cannotCreateMoreX": "Kann mehrere {x} nicht anlegen",
	"cannotDelete": "Kann nicht löschen",
	"noX": "Keine {x}",
	"thisFieldIsFetchedFromX": "Dieses Feld aus {x} entnehmen",
	"addSecondaryColor": "@:secondaryColor hinzufügen",
	"primaryColor": "Primärfarbe",
	"secondaryColor": "Sekundärfarbe",
	"unitMustBeSoldToSetOwner": "Die Einheit muss verkauft/vermietet sein, bevor Sie Eigentümer der Einheit auswählen können",
	"owner": "Eigentümer | Eigentümer",
	"tableFooterTip": "Tipp: {0} drücken und halten, um mehrere gleichzeitig zu markieren.",
	"sendMessage": "Nachricht senden",
	"mediaDescriptionPlaceholder": "Bildunterschrift eingeben",
	"taskTypeDefault": "@:task",
	"taskTypeComplaint": "@:complaint",
	"downloadX": "{x} herunterladen",
	"noUnsavedChanges": "Keine ungesicherten Änderungen",
	"installMore": "Mehrere installieren",
	"propertyType:four-person-home": "Vierfamilienhaus",
	"addCompanyInfo": "Unternehmensinfo hinzufügen",
	"shopTotalLabel": "Gesamtpreis für optionale Ausstattung",
	"addDesiredAmount": "Gewünschten Betrag eingeben unter",
	"creationDate": "Datum angelegt",
	"shop-partialConfirmationMessage": "Wir haben Ihre unterzeichnete Bestellung der optionalen Ausstattung erhalten. Wenn die Gegenpartei diese unterzeichnet hat, erhalten Sie eine Bestätigung. Im Anhang finden Sie eine Übersicht über Ihre Bestellung.",
	"shop-partialAcceptMessage": "Ihr Vertrag über optionale Ausstattung wurde angenommen",
	"shop-partialDeclinedMessage": "Ihr Vertrag über optionale Ausstattung wurde abgelehnt",
	"customConfirmationMessage": "Wir haben Ihren Vertrag erhalten und werden Ihnen so schnell wie möglich antworten",
	"customAcceptMessage": "Ihr Vertrag wurde angenommen",
	"customDeclinedMessage": "Ihr Vertrag wurde abgelehnt",
	"noteVisibilityMessage": "Nur intern sichtbar",
	"flatfinderTypeLeisure": "Hüttenauswahl | Hüttenauswahlen",
	"importContact": "Kontakt importieren",
	"externalProductCategory": "Produkte von externen Lieferanten",
	"shopExternalCategoryLabel": "Sonstige optionale Ausstattung",
	"shopExternalCategoryDescription": "Produkte, die von externen Lieferanten / Kooperationspartnern hinzugefügt wurden",
	"shopExternalProductActionLabel": "Zugriff auf externen Lieferanten erlauben",
	"shopExternalProductActionAlert": "Link unten kopieren und mit Ihren Lieferanten/Kooperationspartnern teilen. Auf Bestätigen drücken, um zur Seite zu gehen.",
	"shopExternalProductInfo": "Im Formular unten können Sie ein neues Produkt im Shop für optionale Ausstattung von {shop} hochladen. ",
	"shopExternalProductAddTitle": "Neue optionale Ausstattung hinzufügen",
	"productName": "Produktname",
	"shopExternalDescriptiveCategoryLabel": "Wo erfolgt die Änderung?",
	"shopExternalSupplierSublabel": "Wer das Produkt hochlädt",
	"canEdit": "Kann bearbeiten",
	"usersWithAccess": "Benutzer mit Zugriff",
	"addon:page": "Seite",
	"createPage": "Seite anlegen",
	"jointDebt": "Gemeinschaftsschulden",
	"hasJointDebt": "Die Einheit hat Gemeinschaftsschulden",
	"premiumDesignTemplateTooltip": "Premium-Designvorlage",
	"about": "",
	"contactBroker": "",
	"energyLabelColor:darkGreen": "",
	"energyLabelColor:lightGreen": "",
	"energyLabelColor:yellow": "",
	"energyLabelColor:orange": "",
	"energyLabelColor:red": "",
	"lift": "",
	"roofTerrace": "",
	"garage": "",
	"janitorservice": "",
	"balcony": "",
	"lookAt": "",
	"rentPageMadeBy": "",
	"submit": "",
	"importStarted": "",
	"available": "",
	"all": "",
	"commercial": "",
	"environmentSustainability": "",
	"clockAt": "bei",
	"admin": "",
	"commercialProperty": "",
	"projectDevelopment": "",
	"seeMorePictures": "Siehe weitere Bilder",
	"customPrivacySublabel": "",
	"customPrivacyAlert": "",
	"privacyPolicyTitle": "",
	"projectConsentDialogDescription": "Wir verwenden Cookies, um Ihnen ein bestmögliches Nutzererlebnis zu ermöglichen.\n Cookies werden verwendet, um die Nutzung der Website zu analysieren, die Website zu verbessern sowie Inhalt und Werbung zu personalisieren. Weitere Informationen finden Sie in unserer {link}.",
	"pageConsentDialogDescription": "Wir verwenden Cookies, um Ihnen ein bestmögliches Nutzererlebnis zu ermöglichen.\n Cookies werden verwendet, um die Nutzung der Website zu analysieren, die Website zu verbessern sowie Inhalt und Werbung zu personalisieren. Weitere Informationen finden Sie in unserer {link}."
}